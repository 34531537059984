<template>
    <div class="row justify-content-center">
        <div class="col-md-3">
            <b-card title="查詢名單" sub-title="這裡可以操作表格">
                <b-card-text>
                    <b-form-group
                            label="不早於"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            label-for="filterInput"
                            class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-datepicker
                                    v-model="table.filter.nbf"
                                    placeholder="選擇一個日期"
                            ></b-form-datepicker>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group
                            label="不晚於"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            label-for="filterInput"
                            class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-datepicker
                                    v-model="table.filter.bef"
                                    placeholder="選擇一個日期"
                            ></b-form-datepicker>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group
                            label="學號"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            label-for="filterInput"
                            class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                    v-model="table.filter.stuId"
                                    trim
                                    placeholder="輸入學號"
                                    @keypress.enter="getList"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group
                            label="日期基準"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            label-for="filterInput"
                            class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-select
                                    v-model="table.filter.dateTpe"
                                    :options="['申請日', '生效日']"
                            ></b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-card-text>
                <br>
                <b-button @click="getList" class="mb-2 mr-sm-2 mb-sm-0" variant="outline-primary" block>搜尋</b-button>
            </b-card>
            <b-card title="匯入晚歸名單" sub-title="請注意，系統不會儲存它">
                <b-card-text>
                    <b-form-file
                        v-model="upload_file"
                        :state="Boolean(upload_file)"
                        placeholder="選擇或把檔案拉過來"
                        drop-placeholder="把檔案拉到這裡～～"
                        accept=".xlsx"
                        browse-text="瀏覽"
                    />
                </b-card-text>
                <br>
                <b-button v-bind:disabled="!Boolean(upload_file)" @click="uploadExcel" class="mb-2 mr-sm-2 mb-sm-0" variant="outline-secondary" block>上傳</b-button>
            </b-card>
        </div>
        <div class="col-md-8">
            <b-row class="row-margin-top">
                <b-col sm="5" md="6">
                    <b-form-group
                            label="每頁"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            label-for="perPageSelect"
                            class="mb-0"
                    >
                        <b-form-select
                                v-model="table.filter.pearPage"
                                size="sm"
                                :options="[10,20,30,40,50,100]"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="7" md="6">
                    <b-pagination
                            v-model="table.filter.currentPage"
                            :total-rows="table.row_item.length"
                            :per-page="table.filter.pearPage"
                            align="fill"
                            size="sm"
                    />
                </b-col>
                <!--<b-button variant="outline-primary" @click="getList" block>搜尋</b-button>-->
            </b-row>
            <div class="w-100"/>
            <b-row>
                <b-table striped hover :current-page="table.filter.currentPage" :per-page="table.filter.pearPage" :items="table.row_item" :fields="table.fields">
                    <template v-slot:cell(action)="row">
                        <b-button size="sm" @click="row.toggleDetails">
                            {{ row.detailsShowing ? '還是不要看好了' : '看看當初的理由' }}
                        </b-button>
                    </template>
                    <template v-slot:cell(name)="row">
                        <router-link :to="{name: 'userManagementDetails', params: {uuid: row.item.uuid }}">{{row.value}}</router-link>
                    </template>
                    <template v-slot:row-details="row">
                        <b-card>
                            <p>理由：<br>{{row.item.reason}}</p>
                        </b-card>
                    </template>
                </b-table>
            </b-row>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import download from "js-file-download";
    export default {
        name: "CurfewExemptCheck",
        data: function () {
            return {
                upload_file: null,
                table: {
                    filter: {
                        nbf: "2020-01-01",
                        bef: "2120-01-01",
                        stuId: "",
                        pearPage: 10,
                        currentPage: 1,
                        dateTpe: "申請日"
                    },
                    row_item: [],
                    fields:[
                        {
                            label: '申請時間',
                            key: 'apply_time',
                            sortable: true
                        },
                        {
                            label: '豁免時間',
                            key: 'exempt_day',
                            sortable: true
                        },
                        {
                            label: '申請人',
                            key: 'name',
                        },
                        {
                            label: '狀態',
                            key: 'status',
                        },
                        {
                            label: '理由',
                            key: 'action'
                        }
                    ]
                }
            }
        },
        methods: {
            uploadExcel: function(){
                let formData = new FormData();
                formData.append('file', this.upload_file);
                axios.post(
                    '/api/misc/CurfewExemptApplyCheck',
                    formData,
                    {
                        headers:{
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    }
                ).then((response)=>{
                    this.upload_file = null;
                    download(response.data, "晚歸記錄_已處理.xlsx");
                })
            },
            getList: function(){
                let filter = this.table.filter;
                axios.get('/api/curfew_exempt/CurfewExemptApplyCheck?nbf='
                    + filter.nbf + "&bef=" + filter.bef + "&stuId=" + filter.stuId + "&dType=" + filter.dateTpe
                ).then((response)=>{
                    this.table.row_item = response.data;
                })
            }
        }
    }
</script>

<style scoped>
    .row-margin-top {
        margin-top: 20px;
    }
</style>